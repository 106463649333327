<template>  
  <div class="background_basic">          
    <v-card class="ma-3">
      <v-toolbar dense flat color="white">
        <v-toolbar-title class="grey--text text--darken-3">
          <v-icon class="mr-2">mdi-map-check</v-icon>직원구역맵핑이력
        </v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-btn color="blue" text @click="initialize()" class="font-weight-bold text-subtitle-1">
          <v-icon big class="mr-2">refresh</v-icon>초기화
        </v-btn>
        <v-btn color="blue" text @click="ExcelExpert('직원구역맵핑')" class = "ml-2 font-weight-bold text-subtitle-1">
          <v-icon big class="mr-2">mdi-microsoft-excel</v-icon>엑셀
        </v-btn>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-divider></v-divider>
      
      <v-card flat class="d-flex ml-3 mr-3">                  
        <v-card flat class="ml-0 mb-n4 mr-3" height="52">             
          <IronDate ref="IronDateRef" v-model="sNowSelectDate" @update="onDateRangeChange"/>
        </v-card>                                                
        <v-text-field
          v-model="cNowSearchKeyWord"
          class="ml-0 mb-n4"
          label="검색어"
          placeholder="이름 or 사용자Id 입력"
          persistent-placeholder
          style="max-width:250px;"
          Regular
          v-on:keyup.enter="Search()"
        ></v-text-field>          
        <v-btn icon class="ml-n8 mt-3 text-subtitle-1" @click="Search()">
          <v-icon>search</v-icon>
        </v-btn>
      </v-card>    
      <v-divider></v-divider>
      <v-data-table         
        :items-per-page="15" 
        fixed-header 
        height="calc(100vh - 247px)" 
        :headers="headers" 
        :items="Records"
        @dblclick:row="handleDoubleClick"
        >
        <template v-slot:item.cIsEmployed="{ item }">
          <v-chip v-if="item.cIsEmployed == 'Y' " color="grey lighten-3" small label>재직</v-chip>
          <v-chip v-else small label color="red lighten-2" dark>퇴사</v-chip>
        </template> 

        <template v-slot:item.cMapFullNm="{ item }">
          <v-btn small @click="editItem(item)" :disabled="!ProgramPermissionInfo.okU" color="grey darken-2" dark><v-icon class="mr-1">map</v-icon>맵선택
          <!-- <v-btn small @click="editItem(item)" :disabled="!ProgramPermissionInfo.okU" color="grey darken-2" dark><v-icon class="mr-1">map</v-icon>맵선택 -->
            
          </v-btn>          
        </template>

        <template v-slot:item.cMapNm="{ item }">
          <v-chip label small v-if ="item.cMapId != 'NN'" color="grey lighten-3">{{item.cMapFullNm}}</v-chip>
          <v-chip label small v-else color="red lighten-2" dark>선택안됨</v-chip>
        </template>

        <template v-slot:no-data>
          <p>No data available.</p>
        </template>
      </v-data-table>  
    </v-card>
  </div>
</template>

<script>
import BasicInfo from "../BasicInfo.js";
import Util from "../Util.js";
import axios from "axios";
import { mapState } from "vuex";
import XLSX from 'xlsx';
import EventBus from '@/eventBus.js';
import IronDate from '../components/iron28Calendar/src/IronDate.vue'


export default {
  components: {
    IronDate,
  },
  data: () => ({
    isGroupALL : false,    
    cGroup : "ALL", 
    sNowSelectDate :  Util.sFormatDate(Date()) + " ~ " +  Util.sFormatDate(Date()),
    sStartDate : Util.sFormatDate(Date()),
    sEndDate : Util.sFormatDate(Date()),
    show1: false,    
    cNowSearchKeyWord : "",    
    loading : false,
    dialog: false,
    model : 0,
    cLev: [],    
    headers: [      
      { class: "font-weight-bold subtitle-2", text: "일시", value: "cDateTime", align: "center", width:180},
      { class: "font-weight-bold subtitle-2", text: "사용자ID", value: "cUserId", align: "center" },
      { class: "font-weight-bold subtitle-2", text: "이름", value: "cUserNm", align: "center" },      
      { class: "font-weight-bold subtitle-2", text: "연락처", value: "cTel", align: "center" },
      { class: "font-weight-bold subtitle-2", text: "회사", value: "cCorpNm", align: "center" },
      { class: "font-weight-bold subtitle-2", text: "부서", value: "cPartNm", align: "center" },
      { class: "font-weight-bold subtitle-2", text: "재직여부", value: "cIsEmployed", align: "center"},
      { class: "font-weight-bold subtitle-2", text: "맵", value: "cMapNms", align: "left" },                  
      { class: "font-weight-bold subtitle-2", text: "수정자", value: "cUserNm2", align: "center" },                  
    ],
    Records: [],
    editedIndex: -1,    
    editedItem: { 
      cUserId: "",
      cUserNm: "",
      cPwd: "",
      cInfo: "",
      cLev: "NONE",
      cCorpId: "NONE",
      cCorpNm: "",      
      cPartNm: "",
      cIsEmployed: "Y",
      cUnregDate: "",
      cUserRoleId: "NONE",
      cMapId: "NONE",
      cEmail: "",
      cBirth: "",
      cPwdChangeDate: "",
      cPwdExpireDate: "",
      cPwdFailCnt: 0,
      cLocked: "N",
      cUse: "",
      cCreateDateTime: "",
      cUpdateDateTime: "",
    },    
  }),

  computed: {
    ...mapState(["UserInfo", "ProgramPermissionInfo", "zIsEmployed", "UserRoleInfo", "zGroupAll"]),
  },

  mounted() {    
    if (this.$store.state.UserInfo.isGroupMode && this.$store.state.UserInfo.GroupId == "ALL") {
      this.isGroupALL = true;
      this.$store.state.GetGroupInfo(); 
    }

    this.initialize();
  },

  methods: {    
    initialize() {
      this.cNowSearchKeyWord = "";
      if (this.$store.state.UserInfo.isGroupMode) {
        this.cGroup = this.$store.state.UserInfo.GroupId;  
      }
      this.sNowSelectDate = Util.sFormatDate(Date()) + " ~ " +  Util.sFormatDate(Date());   
      this.$refs.IronDateRef.SetDate(this.sNowSelectDate);
      this.sStartDate = Util.sFormatDate(Date());
      this.sEndDate = Util.sFormatDate(Date());

      this.Search();      
    },

    handleDoubleClick(value, items) { 
       if (!this.ProgramPermissionInfo.okU) return;
       this.editItem(items.item);  
    },

    Search() {
      this.Records = [];
      var posData = {
        cStartDatetime: this.sStartDate,  
        cEndDatetime  : this.sEndDate,   
        cNowSearchKeyWord : this.cNowSearchKeyWord,
        cNowSiteId : this.UserInfo.NowSiteId,
      };
      
      console.log(posData);

      axios
        .post(BasicInfo.UIL_API + "GetUserForMapping", posData, {
          headers: {
            Authorization: this.$store.state.UserInfo.Token
          }
        })
        .then(res => {
          if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
          
          if (res.data.ResultCd === "00") {
            this.Records = res.data.Result;            
          } else {
            EventBus.$emit("onShowMsg",true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
          }
        })
        .catch(err => {
          EventBus.$emit("onShowMsg",true, ""+err);
        });
    },


    editItem(item) {
      this.isEditMode = true;
      this.editedIndex = this.Records.indexOf(item);   
      this.editedItem = Object.assign({}, item); 

      this.$refs.GetSelMapOneRef.show(); 
    },

    
    ExcelExpert(name){
      var r = Util.GetExcel(this.Records, this.headers);
      var animalWS = XLSX.utils.json_to_sheet(r)       
      var wb = XLSX.utils.book_new() // make Workbook of Excel
      XLSX.utils.book_append_sheet(wb, animalWS, name) // sheetAName is name of Worksheet      
      XLSX.writeFile(wb, name + "-" + Util.sNowDate() + '.xlsx'); // name of the file is 'book.xlsx'
    },

    onDateRangeChange(daterange){            
      this.sStartDate = Util.sFormatDate(daterange.startDate);
      this.sEndDate = Util.sFormatDate(daterange.endDate);      
      this.sNowSelectDate = this.sStartDate + " ~ " +  this.sEndDate;           
    },

  }
};
</script>

